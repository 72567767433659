import autobind from 'autobind-decorator'
import { inject, observer } from 'mobx-react'
import numbro from 'numbro'
import React, { Component } from 'react'
import { Col, Image, Row, Stack, Tab, Tabs } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { Link, Redirect } from 'react-router-dom'
import { i18n, Translation } from '../../common'
import { seatsString } from '../../common/utils'
import payze_logo from '../../resources/logos/payze.png'
import maestro_logo from '../images/demirbank/maestro_logo.png'
import mastercard_logo from '../images/demirbank/mastercard_logo.png'
import visa_logo from '../images/demirbank/visa_logo.png'
import TicketImg from '../images/ticket.png'
import Balance from './payment/Balance'
import Dis from './payment/Dis'
import Elsom from './payment/Elsom'
import Frame3D from './payment/Frame3D'
import PaymentCancel from './payment/PaymentCancel'
import Payze from './payment/Payze'
import { AutoButton, AutoForm, FormCheckbox, FormInput } from './ui/FormInput'

const KEY_DEMIR = 1
const KEY_BALANCE = 2
const KEY_ELSOM = 4
const KEY_PAYZE = 5
const KEY_DIS = 6

@withTranslation()
@inject('store')
@observer
export default class Payment extends Component {
  constructor(props) {
    super(props)
    this.store = this.props.store.payStore
    this.superStore = this.props.store.appState
    this.saveSeats = false
    this.superStore.useWidget('invoice_header')
  }

  componentDidMount() {
    if (this.props.store.appState.account) {
      this.store.setEmail(this.props.store.appState.account.email)
    }
  }

  componentWillUnmount() {
    this.store.reserveCancel(this.saveSeats)
    this.store.restartPayment()
  }

  render() {
    const { repertoryInfo, seats, payment } = this.store
    const {
      disable_demir,
      disable_balance,
      disable_elsom,
      disable_payze,
      disable_dis,
    } = repertoryInfo || {}
    const demir = !disable_demir && this.renderCardTab()
    const balance = !disable_balance && this.renderBalance()
    const elsom = !disable_elsom && this.renderElsom()
    const payze = !disable_payze && this.renderPayze()
    const dis = !disable_dis && this.renderDis()
    return repertoryInfo && seats.length > 0 ? (
      <div className='page padding'>
        <div className='payment'>
          <h2 className='page-title'>
            <Translation ns='payment'>{t => t('title')}</Translation>
          </h2>
          {this.renderTicketDetails()}
          <div className='method'>
            <h4>
              <Translation ns='payment'>{t => t('method')}</Translation>
            </h4>
          </div>
          {payment.status === 'success' ? (
            this.renderSuccess()
          ) : payment.status === 'fail' ? (
            this.renderFail()
          ) : (
            <Tabs transition>
              {demir}
              {balance}
              {elsom}
              {payze}
              {dis}
            </Tabs>
          )}
        </div>
        {this.renderTicketPrint()}
      </div>
    ) : (
      <Redirect to='/' />
    )
  }

  renderTicketDetails() {
    const { repertoryInfo, seats } = this.store
    const { language } = this.props.i18n
    return (
      <div className='ticketBlock'>
        <div className='ticketImg'>
          <Image src={TicketImg} />
        </div>
        <div className='ticketInfo'>
          <Row>
            <Col sm={6} md={4}>
              <Translation ns='payment'>{t => t('movie')}</Translation>
            </Col>
            <Col sm={6} md={8}>
              {repertoryInfo[`movie_${language}`] || repertoryInfo.movie}
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4}>
              <Translation ns='payment'>{t => t('cinema')}</Translation>
            </Col>
            <Col sm={6} md={8}>
              {repertoryInfo[`cinema_${language}`] || repertoryInfo.cinema}
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4}>
              <Translation ns='payment'>{t => t('hall')}</Translation>
            </Col>
            <Col sm={6} md={8}>
              {repertoryInfo[`hall_${language}`] || repertoryInfo.hall}
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4}>
              <Translation ns='payment'>{t => t('places')}</Translation>
            </Col>
            <Col sm={6} md={8}>
              {seatsString(seats)}
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4}>
              <Translation ns='payment'>{t => t('date-time')}</Translation>
            </Col>
            <Col sm={6} md={8}>
              {repertoryInfo.date}
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={4}>
              <Translation ns='payment'>{t => t('price')}</Translation>
            </Col>
            <Col sm={6} md={8} className='text-primary amount'>
              {numbro(this.store.payAmount).formatCurrency()}
            </Col>
          </Row>
        </div>
        <div className='flex' />
      </div>
    )
  }

  renderCardTab() {
    return (
      <Tab
        eventKey={KEY_DEMIR}
        title={i18n.t('bank-card', { ns: 'payment' })}
        className='position-relative'
        transition
      >
        {!this.store.card.url ? (
          this.renderCardForm()
        ) : (
          <>
            <Frame3D {...this.store.card} height={1000} />
            <PaymentCancel payment_id={this.store.card.payment_id} />
          </>
        )}
        <div className='demirbank'>
          <div className='logos'>
            <Image src={visa_logo} />
            <Image src={mastercard_logo} />
            <Image src={maestro_logo} />
          </div>
        </div>
      </Tab>
    )
  }

  renderCardForm() {
    return (
      <Row>
        <Col xs={12} sm={3} />
        <Col xs={12} sm={6}>
          <div className='demir_logo' />
          <AutoForm onSubmit={this.payByCard}>
            <Stack direction='vertical' gap={2}>
              <FormInput
                placeholder={i18n.t('email', { ns: 'payment' })}
                type='email'
                name='email'
                inputName='email'
                model={this.store}
                required
              />
              <FormCheckbox name='acceptCard' model={this.store} required>
                <Translation ns='payment'>{t => t('confirm')}</Translation>{' '}
                <Link
                  target='_blank'
                  to='/pay-agreement'
                  className='conditions'
                >
                  <Translation ns='payment'>{t => t('terms')}</Translation>
                </Link>
                <Translation ns='payment'>{t => t('and')}</Translation>
                <Link target='_blank' to='/ad-info' className='conditions'>
                  <Translation ns='payment'>{t => t('ad-info')}</Translation>
                </Link>
              </FormCheckbox>
              <Row className='justify-content-center'>
                <Col xs='auto'>
                  <AutoButton type='submit' disabled={!this.store.canPayCard}>
                    <Translation ns='payment'>{t => t('pay')}</Translation>
                  </AutoButton>
                </Col>
              </Row>
            </Stack>
          </AutoForm>
        </Col>
      </Row>
    )
  }

  renderBalance() {
    return (
      <Tab
        eventKey={KEY_BALANCE}
        title='Balance.kg'
        className='position-relative'
        transition
      >
        <Balance onPayFailed={this.onPayFailed} />
      </Tab>
    )
  }

  renderElsom() {
    return (
      <Tab
        eventKey={KEY_ELSOM}
        title='ЭЛСОМ'
        className='position-relative'
        transition
      >
        <Elsom onPayFailed={this.onPayFailed} />
      </Tab>
    )
  }

  renderPayze() {
    return (
      <Tab
        eventKey={KEY_PAYZE}
        title='Payze'
        className='position-relative'
        transition
      >
        {!this.store.payze.url ? (
          this.renderPayzeForm()
        ) : (
          <>
            <Payze {...this.store.payze} />
            <PaymentCancel payment_id={this.store.payze.payment_id} />
          </>
        )}
        <div className='payze'>
          <div className='logos'>
            <Image src={payze_logo} />
            <Image src={visa_logo} />
            <Image src={mastercard_logo} />
            <Image src={maestro_logo} />
          </div>
        </div>
      </Tab>
    )
  }

  renderPayzeForm() {
    return (
      <Row>
        <Col xs={12} sm={3} />
        <Col xs={12} sm={6}>
          <div className='payze_logo' />
          <AutoForm onSubmit={this.payByPayze}>
            <Stack direction='vertical' gap={2}>
              <FormInput
                placeholder={i18n.t('email', { ns: 'payment' })}
                type='email'
                name='email'
                inputName='email'
                model={this.store}
                required
              />
              <FormInput
                placeholder={i18n.t('phone', { ns: 'payment' })}
                type='text'
                name='phone'
                inputName='phone'
                model={this.store}
                required
              />
              <FormCheckbox name='acceptCard' model={this.store} required>
                <Translation ns='payment'>{t => t('confirm')}</Translation>{' '}
                <Link
                  target='_blank'
                  to='/pay-agreement'
                  className='conditions'
                >
                  <Translation ns='payment'>{t => t('terms')}</Translation>
                </Link>
                <Translation ns='payment'>{t => t('and')}</Translation>
                <Link target='_blank' to='/ad-info' className='conditions'>
                  <Translation ns='payment'>{t => t('ad-info')}</Translation>
                </Link>
              </FormCheckbox>
              <Row className='justify-content-center'>
                <Col xs='auto'>
                  <AutoButton type='submit' disabled={!this.store.canPayPayze}>
                    <Translation ns='payment'>{t => t('pay')}</Translation>
                  </AutoButton>
                </Col>
              </Row>
            </Stack>
          </AutoForm>
        </Col>
      </Row>
    )
  }

  renderDis() {
    return (
      <Tab
        eventKey={KEY_DIS}
        title='Click/Payme'
        className='position-relative'
        transition
      >
        {!this.store.dis.url ? (
          this.renderDisForm()
        ) : (
          <>
            <Dis {...this.store.dis} />
            <PaymentCancel payment_id={this.store.dis.payment_id} />
          </>
        )}
      </Tab>
    )
  }

  renderDisForm() {
    return (
      <Row>
        <Col xs={12} sm={3} />
        <Col xs={12} sm={6}>
          <div className='dis_logo' />
          <AutoForm onSubmit={this.payByDis}>
            <Stack direction='vertical' gap={2}>
              <FormInput
                placeholder={i18n.t('email', { ns: 'payment' })}
                type='email'
                name='email'
                inputName='email'
                model={this.store}
                required
              />
              <FormInput
                placeholder={i18n.t('phone', { ns: 'payment' })}
                type='text'
                name='phone'
                inputName='phone'
                model={this.store}
                required
              />
              <FormCheckbox name='acceptCard' model={this.store} required>
                <Translation ns='payment'>{t => t('confirm')}</Translation>{' '}
                <Link
                  target='_blank'
                  to='/pay-agreement'
                  className='conditions'
                >
                  <Translation ns='payment'>{t => t('terms')}</Translation>
                </Link>
                <Translation ns='payment'>{t => t('and')}</Translation>
                <Link target='_blank' to='/ad-info' className='conditions'>
                  <Translation ns='payment'>{t => t('ad-info')}</Translation>
                </Link>
              </FormCheckbox>
              <Row className='justify-content-center'>
                <Col xs='auto'>
                  <AutoButton type='submit' disabled={!this.store.canPayDis}>
                    <Translation ns='payment'>{t => t('pay')}</Translation>
                  </AutoButton>
                </Col>
              </Row>
            </Stack>
          </AutoForm>
        </Col>
      </Row>
    )
  }

  @autobind
  payByCard() {
    const { card } = this.props.store.appState
    this.store.payByCard(card ? card.code : undefined).catch(this.onPayFailed)
  }

  @autobind
  payByPayze() {
    const { card } = this.props.store.appState
    this.store.payByPayze(card ? card.code : undefined).catch(this.onPayFailed)
  }

  @autobind
  payByDis() {
    const { card } = this.props.store.appState
    this.store.payByDis(card ? card.code : undefined).catch(this.onPayFailed)
  }

  renderSuccess() {
    let { payment } = this.store
    return (
      <div className='text-center'>
        <div className='message success'>
          <div className='glyphicon'>
            <FaCheck />
          </div>
          <label>
            <Translation ns='payment'>{t => t('success')}</Translation>
          </label>
          <div>
            <Translation ns='payment'>{t => t('number-ticket')}</Translation>{' '}
            <span className='ticketNo'>{payment.ticket}</span>
          </div>
          <div>
            <AutoButton onClick={() => window.print()}>
              <Translation ns='payment'>{t => t('print')}</Translation>
            </AutoButton>
          </div>
        </div>
      </div>
    )
  }

  renderFail() {
    let { payment } = this.store
    return (
      <div className='text-center'>
        <div className='message fail'>
          <div className='glyphicon'>
            <FaTimes />
          </div>
          <label>
            <Translation ns='payment'>{t => t('error')}</Translation>
          </label>
          <div>
            <Translation ns='payment'>{t => t('cause')}</Translation>{' '}
            {payment.message}
          </div>
          <div>
            <AutoButton onClick={this.store.restartPayment}>
              <Translation ns='payment'>{t => t('retry')}</Translation>
            </AutoButton>
          </div>
        </div>
      </div>
    )
  }

  onPayFailed = e => {
    if (e.result === -25) {
      this.saveSeats = true
      this.props.history.goBack()
    }
  }

  renderTicketPrint() {
    const { repertoryInfo, seats, payment } = this.store
    return (
      <div className='print_area'>
        <div
          className='printCinematicaInfo'
          dangerouslySetInnerHTML={{
            __html: this.superStore.widget.get('invoice_header'),
          }}
        />
        {payment.status === 'success' && (
          <div className='printPaymentInfo'>
            <Translation ns='payment'>{t => t('check-number')}</Translation>{' '}
            {payment.ticket}
          </div>
        )}
        <div className='printMovieInfo'>
          <div className='movieTitle'>
            <label>
              <Translation ns='payment'>{t => t('name-movie')}</Translation>
            </label>
            {repertoryInfo.movie}
          </div>
          <div>
            <label>
              <Translation ns='payment'>{t => t('cinema')}</Translation> :
            </label>
            {repertoryInfo.cinema}
          </div>
          <div>
            <label>
              <Translation ns='payment'>{t => t('hall')}</Translation> :
            </label>
            {repertoryInfo.hall}
          </div>
          <div>
            <label>
              <Translation ns='payment'>{t => t('places')}</Translation> :
            </label>
            {seatsString(seats)}
          </div>
          <div>
            <label>
              <Translation ns='payment'>
                {t => t('date-time-sessions')}
              </Translation>
            </label>
            {repertoryInfo.date}
          </div>
          <div>
            <label>
              <Translation ns='payment'>{t => t('amount')}</Translation>
            </label>
            {numbro(this.store.payAmount).formatCurrency()}
          </div>
        </div>
      </div>
    )
  }
}

export class PaymentResult extends Component {
  render() {
    let { url } = this.props.match
    return <div>{url}</div>
  }
}
