import autobind from 'autobind-decorator'
import isString from 'lodash/isString'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormCheck,
  FormControl,
  FormSelect,
  Row,
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { Translation } from 'react-i18next'
import { FaMinus } from 'react-icons/fa'
import { getValue, setModelValue } from '../../../common/utils'
import ApiImage from './ApiImage'
import { VideoPlayerEmbed } from './VideoPlayer'

@inject('store')
@observer
export class FormInput extends Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    uploadCallback: PropTypes.func,
    inputName: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
  }

  @autobind
  onTextChange(event) {
    const { model, name, type } = this.props
    let v = event.target.value
    if (type && type === 'number') {
      v = !v || isNaN(v) ? null : 1 * v
    }
    if (model && (name || typeof name === 'number')) {
      if (typeof name === 'string' && name.indexOf('.') > -1) {
        let [obj, ...key] = name.split('.')
        runInAction(() => (model[obj] = { ...model[obj], [key.join('.')]: v }))
      } else {
        runInAction(() => (model[name] = v))
      }
    }
  }

  @autobind
  onEditorStateChange(e) {
    const data = e.editor.getData()
    this.onTextChange({ target: { value: data } })
  }

  render() {
    const {
      model,
      name,
      type,
      disabled,
      value,
      inputName,
      className,
      store,
      inputRef,
      ...props
    } = this.props
    if (inputRef) props.ref = inputRef
    const v = getValue(model, name, value)
    return type === 'textarea' ? (
      <textarea
        className={'form-control ' + (className || '')}
        name={inputName}
        value={v}
        onChange={this.onTextChange}
        disabled={this.props.store.appState.isBusy || disabled}
        {...props}
      />
    ) : type === 'select' ? (
      <FormSelect
        size={this.props.size}
        className={className}
        name={inputName}
        type={type}
        value={v}
        onChange={this.onTextChange}
        disabled={this.props.store.appState.isBusy || disabled}
        {...props}
      />
    ) : (
      <FormControl
        size={this.props.size}
        className={className}
        name={inputName}
        type={type}
        value={v instanceof File ? v.name : v}
        onChange={this.onTextChange}
        disabled={this.props.store.appState.isBusy || disabled}
        {...props}
      />
    )
  }
}

@inject('store')
@observer
export class FormCheckbox extends Component {
  static propTypes = {
    model: PropTypes.any,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isArray: PropTypes.bool,
  }

  static defaultProps = {
    isArray: false,
  }

  @autobind
  _onChange(event) {
    const { checked } = event.target
    let { model, name, isArray, onChange } = this.props
    if (!isArray) {
      if (model && (name || typeof name === 'number')) {
        setModelValue(model, name, event.target.checked)
      }
      if (onChange) {
        onChange(event.target.checked)
      }
    } else {
      runInAction(() => {
        const i = model.indexOf(name)
        if (i === -1) {
          model.push(name)
        } else {
          model.remove(name)
        }
      })
      if (onChange) {
        onChange(model, name)
      }
    }
  }

  render() {
    const {
      model,
      name,
      checked,
      disabled,
      store,
      isArray,
      label,
      children,
      ...rest
    } = this.props
    const { appState } = store
    let _checked = checked
    if (!isArray) {
      if (model && (name || typeof name === 'number')) {
        _checked = model[name]
      }
    } else {
      _checked = model.includes(name)
    }
    return (
      <FormCheck
        type='checkbox'
        inline
        {...rest}
        disabled={appState.isBusy || disabled}
      >
        <FormCheck.Input
          disabled={appState.isBusy || disabled}
          onChange={this._onChange}
          checked={_checked}
        />
        <FormCheck.Label>{label || children || '\xA0'}</FormCheck.Label>
      </FormCheck>
    )
  }
}

@inject('store')
@observer
export class AutoButton extends Component {
  render() {
    let { store, disabled, ...props } = this.props
    let { appState } = store
    return (
      <Button {...props} disabled={disabled || appState.isBusy}>
        {this.props.children}
      </Button>
    )
  }
}

@observer
export default class FileInput extends Component {
  constructor(props) {
    super(props)
  }

  @autobind
  onDrop(files) {
    let { model, name, onChange, multiple } = this.props
    if (model && name) {
      runInAction(() => {
        if (multiple) {
          model[name] = [].concat([...model[name]]).concat([...files])
        } else {
          model[name] = files[0] || null
        }
      })
    }
    if (onChange) {
      onChange(files)
    }
  }

  remove(f) {
    let { model, name, multiple } = this.props
    runInAction(() => {
      if (multiple) {
        if (f instanceof File) {
          model[name].remove(f)
        } else if (f.preview) {
          model[name].remove(f.preview)
        }
      } else {
        model[name] = null
      }
    })
  }

  render() {
    const { multiple, model, name } = this.props
    let values = []
    if (multiple) {
      values = model[name]
    } else if (model[name]) {
      values = [model[name]]
    }
    return (
      <div>
        {!multiple && (
          <FormInput model={model} name={name} className='margin-bottom' />
        )}
        <Dropzone
          multiple={multiple || false}
          onDropAccepted={this.onDrop}
          accept={{
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'video/mp4': ['.mp4'],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className='drop-zone btn btn-outline-secondary margin-bottom'
            >
              <input {...getInputProps()} />
              <Translation>{t => t('file-input-text')}</Translation>
            </div>
          )}
        </Dropzone>
        {values.map((file, index) => {
          let name = file
          let type = ''
          if (file instanceof File) {
            name = file.path || file.name
            type = file.type
          }
          return (
            <Row key={index}>
              <Col xs={10}>
                {name.endsWith('.mp4') || type === 'video/mp4' ? (
                  <VideoPreview source={file} />
                ) : (
                  <ImagePreview source={file} />
                )}
              </Col>
              <Col xs={2}>
                <AutoButton size='sm' onClick={() => this.remove(file)}>
                  <FaMinus />{' '}
                </AutoButton>
              </Col>
            </Row>
          )
        })}
      </div>
    )
  }
}

function VideoPreview({ source }) {
  const [preview, setPreview] = useState('')

  useEffect(() => {
    if (source instanceof File) {
      const url = URL.createObjectURL(source)
      setPreview(url)
      return () => URL.revokeObjectURL(url)
    }
  }, [source])

  const src = useMemo(
    () => (isString(source) ? source : preview),
    [source, preview],
  )
  return src ? (
    <VideoPlayerEmbed
      autoplay={false}
      controls={true}
      sources={[{ src, type: 'video/mp4' }]}
    />
  ) : null
}

function ImagePreview({ source }) {
  const [preview, setPreview] = useState('')

  useEffect(() => {
    if (source instanceof File) {
      const url = URL.createObjectURL(source)
      setPreview(url)
      return () => URL.revokeObjectURL(url)
    }
  }, [source])

  const src = useMemo(
    () => (isString(source) ? source : preview),
    [source, preview],
  )
  return src ? <ApiImage src={src} thumbnail /> : null
}

FileInput.propTypes = {
  multiple: PropTypes.bool,
  model: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export class AutoForm extends Component {
  onSubmit = e => {
    e.preventDefault()
    const { onSubmit } = this.props
    onSubmit && onSubmit()
    return false
  }

  render() {
    const { children, ...props } = this.props
    return (
      <Form {...props} onSubmit={this.onSubmit}>
        {children}
      </Form>
    )
  }
}

AutoForm.propTypes = {
  onSubmit: PropTypes.func,
}
