import { action, computed, observable } from 'mobx'
import moment from 'moment'
import requester from '../common/requester'
import BaseStore from './BaseStore'

const bgColors = [
  'rgba(198,40,40,.8)',
  'rgba(173,20,87,.8)',
  'rgba(106,27,154,.8)',
  'rgba(21,101,192,.8)',
  'rgba(0,131,143,.8)',
  'rgba(46,125,50,.8)',
  'rgba(249,168,37,.8)',
  'rgba(239,108,0,.8)',
  'rgba(216,67,21,.8)',
]

function getRandomColor() {
  return bgColors[Math.floor(Math.random() * bgColors.length)]
}

const TYPES = [
  { type: 'normal', id: '' },
  { type: '3d', id: '' },
  { type: 'imax', id: '' },
  { type: 'atmos', id: '' },
]

export default class MovieStore extends BaseStore {
  @observable repertories = {}
  @observable soon_movies = observable.array([])
  @observable hasRepertory = true

  movie_colo_map = {}

  @action setSingle(data) {
    if (data) {
      data.details = data.details || details_ru()
      data.details_ru = data.details_ru || details_ru()
      data.details_en = data.details_en || details_en()
      data.details_ky = data.details_ky || details_ky()
      data.details_uz = data.details_uz || details_uz()
      if (!data.images) {
        data.images = []
      }
      if (!data.type) {
        data.type = []
      }
      if (!data.premiere_ids) data.premiere_ids = []
      for (const type of TYPES)
        if (data.premiere_ids.findIndex(i => i.type === type.type) === -1)
          data.premiere_ids.push(type)
      if (
        data.age_restriction === null ||
        typeof data.age_restriction === 'undefined'
      )
        data.age_restriction = ''
    }
    super.setSingle(data)
  }

  @action addDetail(lang) {
    this.item[`details_${lang}`].push({
      order: this.item.details.length,
      title: '',
      value: '',
    })
  }

  @action removeDetail(d, lang) {
    this.item[`details_${lang}`].remove(d)
  }

  @action setRepertories(data) {
    this.repertories = data
  }

  @action.bound setMovieResolution(value) {
    if (this.item) {
      this.item.type.replace(value)
    }
  }

  setData(data, count) {
    for (let item of data) {
      if (!this.movie_colo_map[item.id])
        this.movie_colo_map[item.id] = getRandomColor()
      item.bg_color = this.movie_colo_map[item.id]
    }
    super.setData(data, count)
  }

  async postData(pathname, item = this.item) {
    let images = item.images.filter(k => typeof k === 'string')
    await Promise.all(
      item.images
        .filter(k => k instanceof File)
        .map(async k => {
          let form = new FormData()
          form.append('file', k)
          let { data } = await requester.put(`${pathname}`, form)
          images.push(data.link)
        }),
    )
    item.images.replace(images)
    await super.postData(pathname)
  }

  @action clearItems() {
    super.clearItems()
    this.repertories = {}
  }

  @action clearItem() {
    super.clearItem()
    this.repertories = {}
    this.setHasRepertory(true)
  }

  async getRepertories() {
    let { data } = await requester.get(
      `/repertory/movie/${this.item.id}/grouped`,
    )
    this.setHasRepertory(data.list.length !== 0)
    data.list.forEach(
      item =>
        (item.datetime = moment(`${item.date} ${item.time}`, 'DD.MM.YY HH:mm')
          .subtract(8, 'hours')
          .toISOString()),
    )
    let dates = data.list.reduce((dates, item) => {
      const date = moment(item.datetime).format('DD.MM.YY')
      return { ...dates, [date]: [...(dates[date] || []), item] }
    }, {})
    this.setRepertories(dates)
  }

  @action setHasRepertory(a) {
    this.hasRepertory = a
  }

  @action setSoonMovies(movies) {
    this.soon_movies.replace(movies)
  }

  async getSoonMovies() {
    let { data } = await requester.get(`/movies/soon`)
    for (let item of data.list) {
      if (!this.movie_colo_map[item.id])
        this.movie_colo_map[item.id] = getRandomColor()
      item.bg_color = this.movie_colo_map[item.id]
    }
    this.setSoonMovies(data.list)
  }

  @computed
  get soon_movies_imax() {
    return this.soon_movies.filter(this.filterImax)
  }

  @computed
  get soon_movies_atmos() {
    return this.soon_movies.filter(this.filterAtmos)
  }

  @computed
  get movies_imax() {
    return this.items.filter(this.filterImax)
  }

  @computed
  get movies_atmos() {
    return this.items.filter(this.filterAtmos)
  }

  filterImax = m => m.type.includes('imax')
  filterAtmos = m => m.type.includes('atmos')

  @computed get repertoryDates() {
    return Object.keys(this.repertories).sort((a, b) => {
      let d1 = moment(a, 'DD.MM.YY').toDate()
      let d2 = moment(b, 'DD.MM.YY').toDate()
      return d1 > d2 ? 1 : d2 > d1 ? -1 : 0
    })
  }

  @action
  setYourVote(star) {
    if (this.item) this.item = { ...this.item, yourVote: star }
  }
}

function details_ru() {
  const today = moment()
  return [
    { order: 1, title: 'Год', value: today.year() },
    { order: 2, title: 'Слоган', value: '' },
    { order: 3, title: 'Режиссер', value: '' },
    { order: 4, title: 'Жанр', value: '' },
    { order: 5, title: 'Язык', value: 'Русский' },
    { order: 6, title: 'Субтитры', value: 'Нет' },
    { order: 7, title: 'Длительность', value: '' },
    { order: 8, title: 'Актеры', value: '' },
    { order: 9, title: 'Страна', value: 'США' },
  ]
}

function details_en() {
  const today = moment()
  return [
    { order: 1, title: 'Year', value: today.year() },
    { order: 2, title: 'Slogan', value: '' },
    { order: 3, title: 'Director', value: '' },
    { order: 4, title: 'Genre', value: '' },
    { order: 5, title: 'Language', value: 'Russian' },
    { order: 6, title: 'Subtitles', value: 'No' },
    { order: 7, title: 'Duration', value: '' },
    { order: 8, title: 'Actors', value: '' },
    { order: 9, title: 'Country', value: 'USA' },
  ]
}

function details_ky() {
  const today = moment()
  return [
    { order: 1, title: 'Жылы', value: today.year() },
    { order: 2, title: 'Урааны', value: '' },
    { order: 3, title: 'Режиссеру', value: '' },
    { order: 4, title: 'Жанры', value: '' },
    { order: 5, title: 'Тили', value: 'Орус' },
    { order: 6, title: 'Субтитрлер', value: 'Жок' },
    { order: 7, title: 'Узактыгы', value: '' },
    { order: 8, title: 'Актерлор', value: '' },
    { order: 9, title: 'Өлкөсү', value: 'АКШ' },
  ]
}

function details_uz() {
  const today = moment()
  return [
    { order: 1, title: 'Yil', value: today.year() },
    { order: 2, title: 'Shiori', value: '' },
    { order: 3, title: 'Direktor', value: '' },
    { order: 4, title: 'Janr', value: '' },
    { order: 5, title: 'Til', value: 'Rus' },
    { order: 6, title: 'Subtitrlar', value: "Yo'q" },
    { order: 7, title: 'Davomiyligi', value: '' },
    { order: 8, title: 'Aktyorlar', value: '' },
    { order: 9, title: 'Mamlakat', value: 'AQSh' },
  ]
}
