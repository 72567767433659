import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Translation } from '../../common'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-links'>
        <Translation ns='footer'>
          {t => (
            <>
              <Link to='/contacts'>{t('contacts')}</Link>
              &nbsp;|&nbsp;
              <Link to='/feedback'>{t('feedback')}</Link>
              &nbsp;|&nbsp;
              <Link to='/subscribe'>{t('newsletter')}</Link>
              &nbsp;|&nbsp;
              <Link to='/ad-info'>{t('ad-info')}</Link>
              &nbsp;|&nbsp;
              <Link to='/faq'>{t('faq')}</Link>
              &nbsp;|&nbsp;
              <Link to='/vacancy'>{t('vacancies')}</Link>
              &nbsp;|&nbsp;
              <Link to='/rules'>{t('rules')}</Link>
            </>
          )}
        </Translation>
      </div>
      <div className='footer-brand'>&copy; Cinematica {moment().year()}</div>
    </div>
  )
}

export default Footer
