import classNames from 'classnames'
import { observer, PropTypes as MobxTypes } from 'mobx-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Translation, withTranslation } from 'react-i18next'
import { FaFilm } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { MOVIE_TYPES, TYPE_ICONS } from '../../../common/constants'
import icon_hit from '../../../resources/icons/icon_hit.png'
import icon_recommended from '../../../resources/icons/icon_recommended.png'
import ApiImage from '../ui/ApiImage'

@observer
export default class MoviesToday extends React.Component {
  static propTypes = {
    movies: MobxTypes.arrayOrObservableArray.isRequired,
    soon: PropTypes.bool,
    darkTheme: PropTypes.bool,
  }

  static defaultProps = {
    soon: false,
    darkTheme: false,
  }

  render() {
    return (
      <div
        className={classNames([
          { 'movies-grid-dark': this.props.darkTheme },
          'movies-grid',
          this.props.className,
        ])}
      >
        {this.props.movies.map(this.renderMovie)}
      </div>
    )
  }

  renderMovie = movie => (
    <MovieDummy key={movie.id} movie={movie} soon={this.props.soon} />
  )
}

@withTranslation()
@observer
export class MovieDummy extends React.Component {
  static propTypes = {
    movie: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: MobxTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
      file_poster_vertical: PropTypes.string,
      date_start: PropTypes.string,
      age_restriction: PropTypes.string,
    }),
    soon: PropTypes.bool,
  }

  static defaultProps = { soon: false }

  constructor(props) {
    super(props)
    this.today = moment().startOf('day')
    this.week_ago = this.today.add(-7, 'days')
  }

  render() {
    const {
      movie,
      i18n: { language },
    } = this.props
    return (
      <Link to={`/movies/${movie.id}`} className='movie-dummy'>
        <div className='movie-poster'>
          <ApiImage
            src={movie.file_poster_vertical}
            width={300}
            height={430}
            placeholder={<FaFilm />}
          />
          {this.renderSoon()}
        </div>
        <div className={'movie-title'} title={movie.name}>
          <span
            className={classNames({ 'right-padding': !!movie.age_restriction })}
          >
            {movie.i18n?.[`name_${language}`] || movie.name}
          </span>
          {movie.age_restriction ? (
            <span>({movie.age_restriction})</span>
          ) : null}
        </div>
        <MovieType movie={movie} />
        {this.renderBadge()}
      </Link>
    )
  }

  renderSoon() {
    const { movie } = this.props
    if (!movie.date_start) return null
    if (this.props.soon)
      return (
        <div className='premiere-date'>
          <Translation ns={'movie'}>{t => t('with')}</Translation>{' '}
          {moment(movie.date_start).format('Do MMMM')}
        </div>
      )
    const this_week = moment(movie.date_start).isBetween(
      this.today,
      this.week_ago,
    )
    return this_week ? (
      <div className='premiere-movie'>
        <Translation ns='movie'>{t => t('premiere-week')}</Translation>
      </div>
    ) : null
  }

  renderBadge() {
    const { movie } = this.props
    if (movie.recommended) {
      return <img src={icon_recommended} className='movie-recommended' />
    }
    if (movie.hit) {
      return <img src={icon_hit} className='movie-hit' />
    }
    return null
  }
}

export class MovieType extends React.Component {
  static propTypes = {
    movie: PropTypes.shape({
      type: MobxTypes.arrayOrObservableArrayOf(PropTypes.string),
    }),
  }

  render() {
    const { movie } = this.props
    if (
      movie &&
      movie.type &&
      movie.type.findIndex(t => MOVIE_TYPES.includes(t)) !== -1
    ) {
      const type = MOVIE_TYPES.filter(t => movie.type.includes(t)).join('-')
      return <img src={TYPE_ICONS[type]} className='movie-type' />
    }
    return null
  }
}
