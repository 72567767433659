import 'blueimp-gallery/css/blueimp-gallery.min.css'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/scroll/simplescrollbars.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import { observe } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { i18n } from '../../common'
import { imageUrl } from '../../common/utils'
import Footer from './Footer'
import Home from './Home'
import NotFound from './NotFound'
import { PaymentResult } from './Payment'
import TopBar from './TopBar'
import Loading, { LoadingBar } from './ui/Loading'
import MobileVersion from './ui/MobileVersion'
import VideoPlayer from './ui/VideoPlayer'

const AdminApp = React.lazy(() => import('./admin/App'))
const Card = React.lazy(() => import('./Card'))
const Login = React.lazy(() => import('./Login'))
const Movies = React.lazy(() => import('./Movies'))
const Cinema = React.lazy(() => import('./Cinema'))
const News = React.lazy(() => import('./News'))
const Contacts = React.lazy(() => import('./Contacts'))
const Feedback = React.lazy(() => import('./Feedback'))
const Subscribe = React.lazy(() => import('./Subscribe'))
const Imax = React.lazy(() => import('./Imax'))
const Atmos = React.lazy(() => import('./Atmos'))
const Vip = React.lazy(() => import('./Vip'))
const Payment = React.lazy(() => import('./Payment'))
const PaymentStatus = React.lazy(() => import('./payment/PaymentStatus'))
const Registration = React.lazy(() => import('./landingComp/Registration'))
const NotifNews = React.lazy(() => import('./landingComp/NotifNews'))
const Vacancy = React.lazy(() => import('./landingComp/Vacancy'))
const PayAgreement = React.lazy(() => import('./landingComp/PayAgreement'))
const FAQ = React.lazy(() => import('./FAQ'))
const Rules = React.lazy(() => import('./landingComp/Rules'))
const Privacy = React.lazy(() => import('./landingComp/Privacy'))

@inject('store')
@observer
export default class App extends Component {
  constructor(props) {
    super(props)
    this.store = this.props.store
    this.state = { bannerHeight: null }
    const { search } = window.location
    if (/[?&]+uploaderCallback=true(?:&|$)/.test(search)) {
      const fn_match = decodeURIComponent(search).match(/[?&]fn=(\d+)(?:&|$)/)
      const url_match = decodeURIComponent(search).match(
        /[?&]url=([^&]+)(?:&|$)/,
      )
      if (fn_match && url_match && window.parent && window.parent.CKEDITOR) {
        window.parent.CKEDITOR.tools.callFunction(fn_match[1], url_match[1])
      }
    }
  }

  onBannerLoad = banner => {
    if (banner.background) {
      document.body.style.backgroundColor = banner.background
    }
    if (banner.file_banner) {
      document.body.style.backgroundImage = `url('${imageUrl(
        banner.file_banner,
        2000,
        2000,
      )}')`
    }
    if (banner.height || banner.height > -1) {
      this.setState({ bannerHeight: banner.height })
    }
  }

  componentDidMount() {
    this.store.appState.checkAuth()
    this.store.bannerStore.getBanner().then(this.onBannerLoad)
    this.store.homeStore.getSlider()
    this.store.homeStore.getToday()
    this.store.homeStore.getSoon()
    this.store.homeStore.getNews()
  }

  render() {
    const { banner } = this.store.bannerStore
    const videoJsOptions = banner && {
      autoplay: true,
      controls: false,
      loop: true,
      muted: true,
      sources: [
        {
          src: banner.file_video,
          type: 'video/mp4',
        },
      ],
    }
    return (
      <div className={`wrapper ${this.store.appState.wrapperClassName}`}>
        <LoadingBar show={this.store.appState.isBusy} color='red' />
        <TopBar />
        <div
          className='banner-wrapper'
          style={{
            height:
              this.state.bannerHeight !== null
                ? this.state.bannerHeight + 'px'
                : '250px',
          }}
        >
          {banner && banner.file_video && (
            <div className='bannerVideo'>
              <VideoPlayer {...videoJsOptions} />
            </div>
          )}
          {banner && banner.link && (
            <a href={banner.link} className='bannerLink' target='_blank' />
          )}
        </div>
        <MobileVersion />
        <React.Suspense fallback={<Loading title={i18n.t('page-loading')} />}>
          <Route path='/' exact component={Home} />
          <Route path='/admin' component={AdminApp} />
          <Route path='/card' component={Card} />
          <Route path='/login' component={Login} />
          <Route path='/movies' component={Movies} />
          <Route path='/cinema' component={Cinema} />
          <Route path='/news' component={News} />
          <Route path='/contacts' component={Contacts} />
          <Route path='/feedback' component={Feedback} />
          <Route path='/subscribe' component={Subscribe} />
          <Route path='/imax' component={Imax} />
          <Route path='/atmos' component={Atmos} />
          <Route path='/vip' component={Vip} />
          <Route path='/registration' component={Registration} />
          <Route path='/ad-info' component={NotifNews} />
          <Route path='/vacancy' component={Vacancy} />
          <Route path='/pay-agreement' component={PayAgreement} />
          <Route path='/faq' component={FAQ} />
          <Route path='/rules' component={Rules} />
          <Route path='/privacy' component={Privacy} />
          <Route exact path='/pay' component={Payment} />
          <Route
            exact
            path='/payment/:id/:method/:guid'
            component={PaymentStatus}
          />
          <Route exact path='/pay/ok' component={PaymentResult} />
          <Route exact path='/pay/fail' component={PaymentResult} />
          <Route exact path='/404' component={NotFound} />
        </React.Suspense>
        <Footer />
        <Toastr appState={this.store.appState} />
        <ToastContainer
          position='top-right'
          type='default'
          autoClose={3000}
          closeOnClick
          pauseOnHover
          className='toastify'
        />
      </div>
    )
  }
}

const toastr = { ...toast, danger: toast.error, warning: toast.warn }

function Toastr({ appState }) {
  useEffect(
    () =>
      observe(appState.messages, ({ addedCount = 0, added = [] }) => {
        if (addedCount > 0) {
          for (const { message, level } of added) {
            if (message && level && toastr[level]) {
              toastr[level](message)
            }
          }
        }
      }),
    [appState],
  )
  return null
}
